import photo1 from "../../images/PerfectMan/MAIN1.webp";
import photo2 from "../../images/PerfectMan/MAIN2.webp";
import photo3 from "../../images/PerfectMan/MAIN5.webp";
import photo4 from "../../images/PerfectMan/DSCF6599.webp";
import photo5 from "../../images/PerfectMan/DSCF7530.webp";
import photo6 from "../../images/PerfectMan/DSCF7604.webp";
import photo7 from "../../images/PerfectMan/MAIN4.webp";
import photo8 from "../../images/PerfectMan/DSCF6953.webp";
import photo9 from "../../images/PerfectMan/DSCF6726.webp";


export const PerfectMan = [
    {
        src: photo1,
        type: 'photo',
    },
    {
        src: photo2,
        type: 'photo',
    },
    {
        src: photo3,
        type: 'photo',
    },
    {
        src: photo4,
        type: 'photo',
    },
    {
        src: photo5,
        type: 'photo',
    },
    {
        src: photo6,
        type: 'photo',
    },
    {
        src: photo7,
        type: 'photo',
    },
    {
        src: photo8,
        type: 'photo',
    },
    {
        src: photo9,
        type: 'photo',
    },
    {
        text: [
            "General production: Alexandra Lempert @allle.xandra",
            "Photo: Denis Nemyachenko @denis.nemyachenko",
            "Model: Dmitry Tamakhyn @earl_noire",
            "Style: Max Katt @maxkatt",
            "HMUA: Daria Charles @dashou.charles",
            "Production Assistant: Dinara Kovaleva @maisondinara",
        ],
        special: "Special thanks to George Malakhov @georgy_malakhov",
        firstRow: "Magazine: Perfect Man Mag",
        type: 'credits',
    },
]

export const titlePerfectMan = ["Perfect Man Magazine cover", "Spring 23"];